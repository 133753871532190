var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"amber darken-2","id":"botw","elevation":"24","x-large":"","fab":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"info","left":true,"content":"!"}},[_c('v-avatar',{attrs:{"size":"100"}},[_c('img',{attrs:{"width":"70px","src":require("@/assets/avatar_chat.png")}})])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"width":"70px","src":require("@/assets/avatar_chat.png")}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MACROSOLUTIONS")]),_c('v-list-item-subtitle',[_vm._v("en línea")])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.fav ? 'green--text' : '',attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-face-agent")])],1)],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-container',[_c('v-card',{attrs:{"elevation":"3","outlined":"","shaped":"","width":"auto","color":"white"}},[_c('v-card-text',[_c('p',{staticClass:"font-weight-black",staticStyle:{"max-width":"20rem"}},[_vm._v(" 👋 Hola!, soy Ali tu asistente virtual. Tengo un equipo Macrosolutions que me está enseñando para poder ayudarte cada vez mejor. También podemos conversar sobre los siguientes temas: ¿Con cuál te puedo ayudar?")])])],1)],1),_c('v-divider'),_c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-clipboard-search-outline")])],1),_c('v-list-item-title',[_vm._v("CONSULTAS DE TRAMITES")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require("@/assets/avatar_chat.png")}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MACROSOLUTIONS")]),_c('v-list-item-subtitle',[_vm._v("en línea")])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.fav ? 'green--text' : '',attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-face-agent")])],1)],1)],1),_c('v-divider'),_c('v-container',[_c('v-card',{attrs:{"elevation":"3","outlined":"","shaped":"","width":"78%","color":"white"}},[_c('v-card-text',[_c('p',{staticClass:"font-weight-black",staticStyle:{"max-width":"40rem"}},[_vm._v(" 📑 En esta sección, podés ingresar tus datos y consultar el estado de tus créditos.")])])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"margin-top":"20px","margin-bottom":"30px"},attrs:{"id":"cns-cred"}},[_c('cns-credito')],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.onSimu}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-list-item-title',[_vm._v("SIMULADOR DE CREDITOS")])],1),_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-newspaper-variant-outline")])],1),_c('v-list-item-title',[_vm._v("REQUISITOS")])],1)]}}]),model:{value:(_vm.dialog4),callback:function ($$v) {_vm.dialog4=$$v},expression:"dialog4"}},[_c('v-card',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require("@/assets/avatar_chat.png")}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MACROSOLUTIONS")]),_c('v-list-item-subtitle',[_vm._v("en línea")])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.fav ? 'green--text' : '',attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-face-agent")])],1)],1)],1),_c('v-container',[_c('requisitos')],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog4 = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-clock-time-nine-outline")])],1),_c('v-list-item-title',[_vm._v("HORARIOS")])],1)]}}]),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require("@/assets/avatar_chat.png")}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MACROSOLUTIONS")]),_c('v-list-item-subtitle',[_vm._v("en línea")])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.fav ? 'green--text' : '',attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-face-agent")])],1)],1)],1),_c('v-container',[_c('v-card',{attrs:{"elevation":"3","outlined":"","shaped":"","width":"auto","color":"white"}},[_c('v-card-text',[_c('p',{staticClass:"font-weight-black",staticStyle:{"max-width":"40rem"}},[_vm._v(" 🕘 Nuestros horarios de atención son los siguientes:")])])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-clock-outline")])],1),_c('v-list-item-title',[_vm._v("LUNES A VIERNES DE 9hs a 18hs")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog2 = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-list-item',{attrs:{"link":"","href":"https://api.whatsapp.com/send?phone=5491123765493","target":"blank"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-whatsapp")])],1),_c('v-list-item-title',[_vm._v("CHATEÁ CON NOSOTROS!")])],1),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }